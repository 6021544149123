<template>
	<div class="container">
		<div class="left">
			<img src="../assets/img/login.png" alt="" />
		</div>
		<div class="right">
			<div class="login_box">
				<div class="title">
					<span>修改成功</span>
				</div>
				<div class="successTitle">
					<i
						class="iconfont icon-icon_duihao-mian"
						style="color: #65aa73; font-size: 57px; margin-right: 40px"
					></i>
					<div class="information">
						<span>重置密码成功</span>
						<span>正在跳转登录页 ......</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'login',
		data() {
			return {}
		},
		mounted() {
			this.$Loading.start()
			setTimeout(() => {
				this.$Loading.finish()
				this.$router.replace('/')
			}, 1500)
		},
	}
</script>

<style lang="less" scoped src="../assets/css/rest.less"></style>
