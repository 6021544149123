/* eslint-disable */

/** *****************************************************
 *
 * 管理员UKEY的相关操作（适用于VUE项目）
 *
 * @author ldh
 * @date 2023/03/23
 *
 **********************************************************/
const ret_ok = 0 // 连接成功, 0
const ret_fail = -1 // 数据失败, -1
const ukeyType = 1 // 设置ukey的类型， 0代表标准版， 1代表信创版, 默认使用标准版

/**
 * UKEY服务通信
 * 错误码连接成功为0，连接超时为-2，服务异常为-3，其他错误为返回的错误码
 */
function AjaxIO(json) {
    var xmlHttp = new XMLHttpRequest()
    if (window.ActiveXObject) {
        // xmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
    }

    try {
        xmlHttp.open('POST', 'http://127.0.0.1:51235/alpha', false)
        xmlHttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
        xmlHttp.send('json=' + json)
    } catch (e) {
        return new Result(-2, '连接异常')
    }

    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        // 由JSON字符串转换为JSON对象
        return JSON.parse(xmlHttp.responseText)
    } else {
        return new Result(-3, '服务异常')
    }
}

/**
 * 响应结果信息
 */
function Result(errorCode, msg) {
    this.errorCode = errorCode
    this.msg = msg
}

/**
 * 检查UKEY的控件库是否安装
 */
export function Check_SOF_LoadLibrary() {
    var windows = 'mtoken_gm3000_tass.dll'
    var linux = 'libgm3000_scard.1.0.so'
    var mac = 'libgm3000_scard.1.0.dylib'
    if (ukeyType === 1) {
        windows = 'mtoken_gm3000.dll'
    }
    var json = '{"function":"SOF_LoadLibrary", "winDllName":"' + windows + '", "linuxSOName":"' + linux + '", "macDylibName":"' + mac + '"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = 'UKEY控件未安装,请先返回XXX页面下载UKEY控件'
        if (ukeyType === 1) {
            ret.msg = 'UKEY控件未安装,请先返回XXX页面下载UKEY控件'
        }
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 获取所有检测到的UKEY设备名
 */
export function SOF_EnumDevice() {
    // 检测ukey控件是否已经安装
    var ret = Check_SOF_LoadLibrary()
    if (ret_ok !== ret.errorCode) {
        return ret
    }
    var json = '{"function":"SOF_EnumDevice"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = '获取所有检测到的ukey设备名失败；' + ret.errorCode
        return ret
    }
    if (ret.array === null || ret.array === '' || ret.array === undefined) {
        ret.msg = '请检查UKEY是否插入正常, 获取ukey设备名为空'
        return new Result(-1, ret.msg)
    }
    return new Result(ret.errorCode, ret.array)
}

/**
 * UEKY登陆
 */
export function SOF_Login(pin, deviceName, serialNumber) {
    var json = '{"function":"SOF_Login", "deviceName":"' + deviceName + '", "passWd":"' + pin + '"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        // UKEY剩余可以输错pin码次数查询
        var ret1 = SOF_GetPinRetryCount(deviceName, serialNumber)
        if (ret1.errorCode !== ret_ok) {
            ret1.msg = '请检查UKEY是否插入正常, 获取ukey设备名为空'
            return ret1
        }
        if (ret1.retryCount === 0) {
            ret.msg = serialNumber + '登录失败：请检查是否绑定UKEY或者pin输入是否正确'
        } else {
            ret.msg = serialNumber + '登录失败：请检查是否绑定UKEY或者pin输入是否正确'
        }
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 获取ukey设备实例
 */
export function SOF_GetDeviceInstance(deviceName) {
    var json = '{"function":"SOF_GetDeviceInstance", "deviceName":"' + deviceName + '", "applicationName":""}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = deviceName + "请检测UKEY是否插入正常, 获取ukey设备实例失败：" + ret.errorCode
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 获取UKEY序列号信息
 */
export function SOF_GetDeviceInfo(deviceName) {
    var SGD_DEVICE_SERIAL_NUMBER = 0x00000208
    var json = '{"function":"SOF_GetDeviceInfo", "deviceName":"' + deviceName + '", "type": ' + SGD_DEVICE_SERIAL_NUMBER + '}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = deviceName + '获取UKEY序列号信息失败：' + ret.errorCode
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.info)
}

/**
 * 取得当前已安装证书的用户列表
 */
export function SOF_GetUserList(deviceName) {
    var json = '{"function":"SOF_GetUserList", "deviceName":"' + deviceName + '"}';
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        SOF_ReleaseDeviceInstance(deviceName)
        ret.msg = deviceName + "获取UKEY容器失败：" + ret.errorCode
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.array)
}

/**
 * 导出用户证书
 */
export function SOF_ExportUserCert(ContainerName, deviceName, serialNumber) {
    var json = '{"function":"SOF_ExportUserCert", "deviceName":"' + deviceName + '", "containerName":"' + ContainerName + '", "keySpec": 1}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = serialNumber + '根导出用户证书失败：' + ret.errorCode
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.cert)
}

export function SOF_GetCertInfo(Base64EncodeCert, Type) {
    var json = '{"function":"SOF_GetCertInfo", "base64EncodeCert":"' + Base64EncodeCert + '", "type":' + Type + '}';

}

/**
 * 生成随机数
 */
export function SOF_GenerateRandom(deviceName, length) {
    var json = '{"function":"SOF_GenRandom", "deviceName":"' + deviceName + '", "inDataLen":' + length + '}';
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = '生成随机数失败：' + ret.errorCode
        SOF_ReleaseDeviceInstance(deviceName)
        return ret
    }
    return new Result(ret.errorCode, ret.outData)
}

/**
 * ukey的sm2签名
 */
export function SIGN_DATA(curDevName, serialNumber, containerName, pin, srcData) {
    // 设置算法
    var DigestMethod = 0x00000001 // SGD_SM3
    var ret = SOF_SetDigestMethod(DigestMethod, curDevName, serialNumber)
    if (ret_ok !== ret.errorCode) {
        SOF_LogOut(curDevName)
        return ret
    }

    // 设置userid
    var UserID = '1234567812345678'
    ret = SOF_SetUserID(UserID, curDevName, serialNumber)
    if (ret_ok !== ret.errorCode) {
        SOF_LogOut(curDevName)
        return ret
    }

    // 计算签名值
    ret = SOF_SignData(containerName, srcData, curDevName, serialNumber)
    if (ret_ok !== ret.errorCode) {
        SOF_LogOut(curDevName)
        return ret
    }

    SOF_LogOut(curDevName)
    SOF_ReleaseDeviceInstance(curDevName)
    return ret
}

/**
 * 签名
 */
function SOF_SignData(containerName, InData, curDevName, serialNumber) {
    var json = '{"function":"SOF_SignDataEx", "deviceName":"' + curDevName + '", "containerName":"' + containerName + '", "keySpec": true, "inData":"' + InData + '", "inDataLen":' + InData.length + '}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = '签名计算失败' + ret.errorCode + ';' + serialNumber
        SOF_ReleaseDeviceInstance(curDevName)
        return ret
    }
    return new Result(ret.errorCode, ret.signed)
}

/**
 * 设置摘要算法
 */
function SOF_SetDigestMethod(DigestMethod, curDevName, serialNumber) {
    var json = '{"function":"SOF_SetDigestMethod","deviceName":"' + curDevName + '", "digestMethod":' + DigestMethod + '}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = serialNumber + '设置摘要算法失败：' + ret.errorCode
        SOF_ReleaseDeviceInstance(curDevName)
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 设置UseId
 */
function SOF_SetUserID(UserID, curDevName, serialNumber) {
    var json = '{"function":"SOF_SetUserID","deviceName":"' + curDevName + '", "userID":"' + UserID + '"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = serialNumber + i18nTip.getTip().setUserIdFailure + ret.errorCode
        SOF_ReleaseDeviceInstance(curDevName)
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 退出登陆
 */
function SOF_LogOut(curDevName, serialNumber) {
    var json = '{"function":"SOF_LogOut", "deviceName":"' + curDevName + '"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = serialNumber + '退出登陆失败：' + ret.errorCode
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 释放资源
 */
function SOF_ReleaseDeviceInstance(curDevName) {
    var json = '{"function":"SOF_ReleaseDeviceInstance", "deviceName":"' + curDevName + '"}'
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        ret.msg = curDevName + '释放资源失败：' + ret.errorCode
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}

/**
 * 查询次数
 */
function SOF_GetPinRetryCount(curDevName) {
    var json = '{"function":"SOF_GetPinRetryCount", "deviceName":"' + curDevName + '"}';
    var ret = AjaxIO(json)
    if (ret.errorCode !== ret_ok) {
        return ret
    }
    return new Result(ret.errorCode, ret.rtn)
}