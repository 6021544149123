<template>
  <div>
    <Form
      :model="mainFrom"
      ref="mainFrom"
      label-position="right"
      :label-width="0"
      :rules="ruleValidate"
      @submit.prevent=""
      @keyup.native.enter="sumbit('mainFrom')"
    >
      <FormItem label="" prop="account">
        <Input v-model="mainFrom.account" placeholder="管理员登录账号">
          <i slot="prefix" class="iconfont icon-ziyonghuzhanghaobeifen"></i>
        </Input>
      </FormItem>
      <FormItem label="" prop="pin">
        <Input
          v-model="mainFrom.pin"
          placeholder="密码"
          type="password"
          @keyup.enter="sumbit('mainFrom')"
        >
          <i slot="prefix" class="iconfont icon-a-mimabeifen2"></i
        ></Input>
      </FormItem>
      <div class="forget" style="margin-bottom: 10px">
        <span class="hand" @click="$emit('changeSelectVal', 2)"
          >手机验证码登录</span
        >
        <span class="hand" @click="$emit('changeSelectVal', 3)">忘记密码</span>
      </div>
      <div class="login_submit">
        <Button type="primary" @click="sumbit('mainFrom')">登录</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import * as apiServices from '@/api'
import { gmcryptSm4 } from '@/until/core.js'
import {
  Check_SOF_LoadLibrary,
  SOF_EnumDevice,
  SOF_GetDeviceInfo,
  SOF_Login,
  SOF_GetUserList,
  SOF_ExportUserCert,
  SOF_GenerateRandom,
  SIGN_DATA,
} from '@/until/ukey/ukey'
import { encode64 } from '@/until/ukey/base64JS'
import { getRandomR } from '@/api/index'
const retOk = 0 // 连接成功, 0
export default {
  props: {
    browerData: {
      type: Object,
      required: true,
    },
    captchaVerification: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mainFrom: {
        account: '',
        pin: '',
      },
      ruleValidate: {
        account: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'change',
          },
        ],
        pin: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    refresh() {
      let clientId = this.browerData.clientId
      let redirectUri = this.browerData.redirectUri
      apiServices
        .refresh({
          clientId: clientId,
          redirectUri: redirectUri,
        })
        .then((res) => {
          window.location = res.data
        })
        .catch((err) => {})
    },
    async sumbit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          // 检查是控制件
          var ret = Check_SOF_LoadLibrary()
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }
          // 检查是否存在UKEY
          ret = SOF_EnumDevice()
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }
          // 获取UKEY序列号
          var deviceName = ret.msg.split('||')[0]
          var deviceInfo = SOF_GetDeviceInfo(deviceName)
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }
          var serialNumber = deviceInfo.msg
          // 登录UKY
          ret = SOF_Login(this.mainFrom.pin, deviceName, serialNumber)
          if (ret.errorCode !== retOk) {
            return alert(ret.msg)
          }

          // 获取UKEY容器
          ret = SOF_GetUserList(deviceName)
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }
          var userStr = ret.msg.split('&&&')[0]
          var containerName = userStr.split('||')[1]
          // 导出用户证导出用户证书
          var certInfo = SOF_ExportUserCert(
            containerName,
            deviceName,
            serialNumber
          )
          if (certInfo.errorCode !== retOk) {
            alert(certInfo.msg)
          }

          // 生成随机数R1
          ret = SOF_GenerateRandom(deviceName, 16)
          if (ret.errorCode !== retOk) {
            alert(ret.msg)
          }

          var randomR1 = ret.msg
          // 向服务器端获取随机数2 > 后续步骤
          this.getRandomR(
            this.mainFrom.account,
            randomR1,
            serialNumber,
            deviceName,
            containerName
          )
        }
      })
    },
    // 获取随机数2
    getRandomR(account, randomR1, serialNumber, deviceName, containerName) {
      getRandomR({
        account: account,
        sourceRandom: randomR1,
        serialNumber: serialNumber,
      }).then((res) => {
        this.randomR2 = res.data

        // 向UKEY请求签名
        var sourceData =
          randomR1 + this.randomR2 + this.mainFrom.account + serialNumber
        var inData = encode64(sourceData)
        var ret = SIGN_DATA(
          deviceName,
          serialNumber,
          containerName,
          this.mainFrom.pin,
          inData
        )

        if (ret.errorCode !== retOk) {
          alert(ret.msg)
        }
        var ukeySin = ret.msg

        // 请求后端验签
        this.mainFrom.serialNumber = serialNumber
        this.mainFrom.randomOne = randomR1
        this.mainFrom.randomTwo = this.randomR2
        this.mainFrom.sourceText = sourceData
        this.mainFrom.sourceBaseEncode = inData
        this.mainFrom.ukeySing = ukeySin

        this.$nextTick(function () {
          this.$emit('showVerify')
        })
      })
    },
    async success(captchaVerification) {
      // let data = {
      //   ...this.browerData,
      //   account: this.mainFrom.account,
      //   password: gmcryptSm4(this.mainFrom.pin),
      //   captchaVerification: captchaVerification,
      // }
      let data = {
        ...this.browerData,
        ...this.mainFrom,
        captchaVerification: captchaVerification,
      }
      // 子用户链接跳转登录中传递的id和page字段
      data.id && delete data.id
      data.page && delete data.page
      apiServices
        .doLogin(data)
        .then((res) => {
          window.location = res.data
        })
        .catch((err) => {
          if (err.errorcode == 2000) {
            this.refresh()
            //登录超时需要刷新
            return
          }
        })
      // let res = await apiServices.mainLogin(data)
      // window.location = res.data
    },
  },
}
</script>
<style lang="less"></style>
