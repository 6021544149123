import axios from 'axios'
import { Message, LoadingBar } from 'view-design'

const instance = axios.create({
    //打包配置修改
    // baseURL: 'http://192.168.2.80:8080/platform-sso', //本地
    // baseURL: 'http://101.43.161.167:7100/platform-sso', //本地
    // baseURL: 'http://101.43.161.167/platform-gateway/platform-sso', //本地
    baseURL: 'https://gl.lvchenglvyou.net/platform-gateway/platform-sso',
    // baseURL: 'http://192.168.2.80:1811/platform-sso',
    timeout: 8000,
    headers: { 'X-Custom-Header': 'foobar' },
})

instance.interceptors.request.use(
    function (config) {
        console.log(config, '...config')
        // 在发送请求之前做些什么
        LoadingBar.start()
        return config
    },
    function (error) {
        Message.error(error)
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器 
instance.interceptors.response.use(
    (res) => {
        if (res.data.ret == 0) {
            LoadingBar.finish()
            return Promise.resolve(res.data)
        } else {
            if (res.data.errorcode == 2000) { //需要刷新  
                return Promise.reject(res.data)
            } else {
                LoadingBar.error()
                Message.error(res.data.msg)
                return Promise.reject(res.data)
            }
        }
    },
    (err) => {
        return Promise.reject(err)
    }
)

const http = (options) => {
    return instance(options)
}
export default http