import {
    Check_SOF_LoadLibrary,
    SOF_EnumDevice,
    SOF_GetDeviceInfo,
    SOF_Login,
    SOF_GetUserList,
    SOF_ExportUserCert,
    SOF_GenerateRandom,
    SIGN_DATA,
} from '@/until/ukey/ukey'
import { encode64 } from '@/until/ukey/base64JS'
import { getRandomR } from '@/api/index'

const retOk = 0 // 连接成功, 0
export const checkAll = async(obj) => {
    // 检查是控制件
    var ret = Check_SOF_LoadLibrary()
    console.log(ret, '...ret')
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    // 检查是否存在UKEY
    ret = SOF_EnumDevice()
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    // 获取UKEY序列号
    var deviceName = ret.msg.split('||')[0]
    var deviceInfo = SOF_GetDeviceInfo(deviceName)
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    var serialNumber = deviceInfo.msg
        // 登录UKY
    ret = SOF_Login(obj.password, deviceName, serialNumber)
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }

    // 获取UKEY容器
    ret = SOF_GetUserList(deviceName)
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    var userStr = ret.msg.split('&&&')[0]
    var containerName = userStr.split('||')[1]
        // 导出用户证导出用户证书
    var certInfo = SOF_ExportUserCert(
        containerName,
        deviceName,
        serialNumber
    )
    if (certInfo.errorCode !== retOk) {
        alert(certInfo.msg)
    }

    // 生成随机数R1
    ret = SOF_GenerateRandom(deviceName, 16)
    if (ret.errorCode !== retOk) {
        alert(ret.msg)
    }
    var randomR1 = ret.msg
        // 向服务器端获取随机数2 > 后续步骤
        // const data = await getRandomRs(
        //     this.subFrom.user,
        //     randomR1,
        //     serialNumber,
        //     deviceName,
        //     containerName,
        //     obj
        // )
    return { name: "1111" };
}

export const getRandomRs = async(account, randomR1, serialNumber, deviceName, containerName, obj) => {
    return await getRandomR({
        account: account,
        sourceRandom: randomR1,
        serialNumber: serialNumber,
    }).then(async(res) => {
        var randomR2 = res.data

        // 向UKEY请求签名
        var sourceData =
            randomR1 + randomR2 + obj.user + serialNumber
        var inData = encode64(sourceData)
        var ret = SIGN_DATA(
            deviceName,
            serialNumber,
            containerName,
            obj.password,
            inData
        )
        if (ret.errorCode !== retOk) {
            alert(ret.message)
        }
        var ukeySin = ret.msg

        const form = {
            // 请求后端验签
            serialNumber: serialNumber,
            randomOne: randomR1,
            randomTwo: randomR2,
            sourceText: sourceData,
            sourceBaseEncode: inData,
            ukeySing: ukeySin,
        }
        return Promise.resolve(form)
    })
}

export default {
    checkAll,
    getRandomRs
}