import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design'
import Router from './router'
import 'view-design/dist/styles/iview.css'
import '@/assets/icons/iconfont.css'

Vue.use(ViewUI)

Vue.config.productionTip = false

import tools from '@/until/tools'
Object.keys(tools).forEach(it => {
    Vue.prototype[`$${it}`] = tools[it]
})
new Vue({
    render: (h) => h(App),
    router: Router,
}).$mount('#app')