<template>
  <div class="container">
    <!-- <div class="top">
          <img style="width: 32px;height: 32px;margin-right:10px"
            src="../assets/img/50_50.png" />
          <img class="logo-con-img"
            src="../assets/img/zhiHuiWenLvText.png" />
    </div>-->
    <div class="top">
      <img src="../assets/img/logo@2x.png" class="top-logo" alt />
      <img src="../assets/img/zhiHuiWenLvText.png" class="top-title" />
      <!-- <span class="top-title">鄂尔多斯文化产业园</span> -->
    </div>
    <div class="wrap">
      <div class="left">
        <div class="login_box">
          <div class="title" style="margin-bottom: 60px">
            <span>登录</span>
          </div>
          <div v-if="selectVal == 1">
            <Tabs v-model="tabval">
              <TabPane label="管理员登录" name="1">
                <mainFrom
                  style="margin-top: 30px"
                  ref="mainFrom"
                  :browerData="browerData"
                  :captchaVerification="captchaVerification"
                  @showVerify="$refs.verify.show()"
                  @changeSelectVal="changeSelectVal"
                />
              </TabPane>
              <TabPane label="子账户登录" name="2">
                <subFrom
                  ref="subFrom"
                  style="margin-top: 30px"
                  :browerData="browerData"
                  :captchaVerification="captchaVerification"
                  @showVerify="$refs.verify.show()"
                  @changeSelectVal="changeSelectVal"
                />
              </TabPane>
            </Tabs>
          </div>
          <div v-if="selectVal == 2">
            <div class="forget_phone">
              <span
                style="
									display: flex;
									margin-bottom: 40px;
									font-weight: 400;
									font-size: 22px;
									font-weight: 400;
									color: #000000;
								"
              >手机验证码登录</span>
              <Form
                :model="loginByPhone"
                label-position="right"
                :label-width="0"
                :rules="phoneValidate"
                ref="loginByPhone"
                @submit.prevent
                @keyup.enter="showVcode('loginByPhone')"
              >
                <FormItem label prop="phone">
                  <Input v-model="loginByPhone.phone" placeholder="手机号">
                    <i slot="prefix" class="iconfont icon-shoujibeifen"></i>
                  </Input>
                </FormItem>
                <FormItem label prop="code">
                  <Row class="code">
                    <Col span="17">
                      <Input
                        v-model="loginByPhone.code"
                        placeholder="验证码"
                        @keyup.enter="showVcode('loginByPhone')"
                      >
                        <i slot="prefix" class="iconfont icon-shoujiyanzhengmabeifen"></i>
                      </Input>
                    </Col>
                    <Col span="7">
                      <Button @click="showVcode('loginByPhone')" :disabled="codeSt">{{ codeMsg }}</Button>
                    </Col>
                  </Row>
                </FormItem>
                <div class="forget" style="margin-bottom: 10px">
                  <span class="hand" @click="changeSelectVal(1)">返回账号密码登录</span>
                  <span class="hand" @click="changeSelectVal(3)">忘记密码</span>
                </div>
                <div class="login_submit">
                  <Button type="primary" @click="sumbit('loginByPhone')">登录</Button>
                </div>
              </Form>
            </div>
          </div>
          <div v-if="selectVal == 3">
            <div class="forget_phone">
              <span
                style="
									display: flex;
									margin-bottom: 40px;
									font-weight: 400;
									font-size: 22px;
									font-weight: 400;
									color: #000000;
								"
              >忘记密码</span>
              <Form
                :model="forgetbyPhone"
                ref="forgetbyPhone"
                :rules="phoneValidate"
                label-position="right"
                :label-width="0"
                @submit.prevent
                @keyup.enter="forgetsumbit('forgetbyPhone')"
              >
                <FormItem label prop="phone">
                  <Input v-model="forgetbyPhone.phone" placeholder="手机号">
                    <i slot="prefix" class="iconfont icon-shoujibeifen"></i>
                  </Input>
                </FormItem>
                <FormItem label prop="code">
                  <Row class="code">
                    <Col span="17">
                      <Input v-model="forgetbyPhone.code" placeholder="验证码">
                        <i slot="prefix" class="iconfont icon-shoujiyanzhengmabeifen"></i>
                      </Input>
                    </Col>
                    <Col span="7">
                      <Button :disabled="codeSt" @click="showVcode('forgetbyPhone')">{{ codeMsg }}</Button>
                    </Col>
                  </Row>
                </FormItem>

                <div class="login_submit">
                  <Button type="primary" @click="forgetsumbit('forgetbyPhone')">下一步</Button>
                </div>

                <div class style="margin-top: 29px; color: #006EFE; font-size: 15px">
                  <i class="iconfont icon-fanhuijiantoubeifen" style="font-size: 14px"></i>
                  <span class="hand" @click="changeSelectVal(1)">返回登录</span>
                </div>
              </Form>
            </div>
          </div>
          <div v-if="selectVal == 4">
            <div class="forget_phone">
              <span
                style="
									display: flex;
									margin-bottom: 40px;
									font-weight: 400;
									font-size: 22px;
									font-weight: 400;
									color: #000000;
								"
              >忘记密码</span>
              <Form
                :model="editPassword"
                ref="editPassword"
                :rules="editPasswordValidate"
                label-position="right"
                :label-width="0"
                @submit.prevent
                @keyup.enter="editSubmit('editPassword')"
              >
                <FormItem label prop="account">
                  <Select v-model="editPassword.account" placeholder="请选择你的账号">
                    <template v-for="(item, index) in account">
                      <Option
                        :value="item.id"
                        v-if="item.mainFlag&&item.userCode!=null&&item.userCode!=''"
                        :key="index"
                        @click.native="selectAccount(item.mainFlag, index)"
                      >主账号： {{ item.userCode }}</Option>
                      <Option
                        :value="item.id"
                        v-if="!item.mainFlag&&item.userCode!=null&&item.userCode!=''"
                        :key="index"
                        @click.native="selectAccount(item.mainFlag, index)"
                      >
                        子账号： {{ item.userCode }} （所属主体：
                        {{ item.mainUserCode }}）
                      </Option>
                    </template>
                  </Select>
                </FormItem>

                <FormItem label prop="password">
                  <Input v-model="editPassword.password" placeholder="新密码" type="password">
                    <i slot="prefix" class="iconfont icon-a-mimabeifen2"></i>
                  </Input>
                </FormItem>
                <FormItem label prop="newPassword">
                  <Input
                    v-model="editPassword.newPassword"
                    type="password"
                    placeholder="再次确认密码"
                    @keyup.enter="editSubmit('editPassword')"
                  >
                    <i slot="prefix" class="iconfont icon-a-mimabeifen2"></i>
                  </Input>
                </FormItem>

                <div class="forget" style="margin-bottom: 10px">
                  <span class="hand" @click="changeSelectVal(1)">返回账号密码登录</span>
                </div>
                <div class="login_submit">
                  <Button type="primary" @click="editSubmit('editPassword')">确定修改</Button>
                </div>
              </Form>
            </div>
          </div>

          <div v-if="selectVal == 5">
            <div class="forget_phone">
              <span
                style="
									display: flex;
									margin-bottom: 40px;
									font-weight: 400;
									font-size: 22px;
									font-weight: 400;
									color: #000000;
								"
              >验证码登录</span>
              <Form
                :model="editPassword"
                ref="editPassword"
                :rules="editPasswordValidate"
                label-position="right"
                :label-width="0"
                @keyup.enter="login('editPassword')"
              >
                <FormItem label prop="account">
                  <Select v-model="editPassword.account" placeholder="请选择你的账号">
                    <template v-for="(item, index) in account">
                      <Option
                        :value="item.id"
                        v-if="item.mainFlag&&item.userCode!=null&&item.userCode!=''"
                        :key="item.id"
                        @click.native="selectAccount(item.mainFlag, index)"
                      >主账号： {{ item.userCode }}</Option>
                      <Option
                        class="css_account"
                        :value="item.id"
                        v-if="!item.mainFlag&&item.userCode!=null&&item.userCode!=''"
                        :key="item.id"
                        @click.native="selectAccount(item.mainFlag, index)"
                      >
                        子账号： {{ item.userCode }} （所属主体：
                        {{ item.mainUserCode }}）
                      </Option>
                    </template>
                  </Select>
                </FormItem>

                <div class="login_submit">
                  <Button type="primary" @click="login('editPassword')">登录</Button>
                </div>
              </Form>
            </div>
          </div>
          <!-- <Vcode :show="isShow" @success="onSuccess" @close="onClose" /> -->
          <verify
            ref="verify"
            @success="onSuccess"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
          />
        </div>
      </div>

      <div class="right">
        <span class="right-title">鄂尔多斯文化产业园</span>
        <div class="right-list">
          <span>•</span>
          <span class="right-txt">系统集成接入</span>
        </div>
        <div class="right-list">
          <span>•</span>
          <span class="right-txt">统一用户集权</span>
        </div>
        <div class="right-list">
          <span>•</span>
          <span class="right-txt">资产集成总览</span>
        </div>
      </div>
    </div>

    <div class="bottom">copyright© 2022鄂尔多斯文化产业园版权所有</div>
  </div>
</template>

<script>
import verify from '@/components/verifition/Verify'
import Vcode from 'vue-puzzle-vcode'
import mainFrom from './mainFrom.vue'
import subFrom from './subFrom.vue'
import { gmcryptSm4 } from '@/until/core.js'
import * as coreServices from '@/until/core.js'
import * as apiServices from '@/api'
export default {
  name: 'login',
  components: {
    mainFrom,
    subFrom,
    Vcode,
    verify,
  },
  mounted() {
    let url = decodeURIComponent(location.href)
    const data = url.split('?')[1].split('&')
    const params = {}
    for (let i = 0; i < data.length; i++) {
      const str = data[i].split('=')
      params[str[0]] = str[1]
    }

    //游览器传递的参数
    this.browerData = {
      ...this.$route.query,
    }
    this.browerData.redirectUri = params.redirectUri
    if (this.browerData.page) {
      this.isPage = true
      this.loginpath(this.browerData.page)
    }
    if (this.browerData.id) this.tabval = '2'
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editPassword.password) {
        callback(new Error('第一次输入密码与第二次不匹配!'))
      } else {
        callback()
      }
    }
    return {
      //游览器传递的参数
      tabval: '2',
      browerData: {},
      captchaVerification: '',
      codeMsg: '获取验证码',
      codeSt: false,
      isShow: false,
      selectVal: 1,
      mainFlag: false,
      bindAccountKey: 0,
      forgetbyPhone: {
        phone: '',
        code: '',
      },
      loginByPhone: {
        phone: '',
        code: '',
      },
      account: [],
      editPassword: {
        account: '',
        password: '',
        newPassword: '',
      },
      editPasswordValidate: {
        account: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'change',
          },
        ],
        newPassword: [
          {
            validator: validatePassCheck,
            trigger: 'change',
          },
        ],
      },
      phoneValidate: {
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'change',
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号码格式不正确',
            trigger: 'change',
          },
        ],
        code: [
          {
            required: true,
            message: '验证码不能为空',
            trigger: 'change',
          },
        ],
      },
    }
  },

  methods: {
    refresh() {
      let clientId = this.browerData.clientId
      let redirectUri = this.browerData.redirectUri
      apiServices
        .refresh({
          clientId: clientId,
          redirectUri: redirectUri,
        })
        .then((res) => {
          window.location = res.data
        })
        .catch((err) => {})
    },
    login(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          let selected = this.account.slice(
              this.bindAccountKey,
              this.bindAccountKey + 1
            )[0],
            notSelected = [
              ...this.account.slice(this.bindAccountKey + 1),
              ...this.account.slice(0, this.bindAccountKey),
            ],
            data = {
              selected,
              notSelected,
              ...this.browerData,
            }

          apiServices
            .loginByPhoneLogin(data)
            .then((res) => {
              window.location = res.data
            })
            .catch((err) => {
              if (err.errorcode == 2000) {
                this.refresh()
                //登录超时需要刷新
                return
              }
            })
          // let res = await apiServices.loginByPhoneLogin(data)
          // window.location = res.data
        }
      })
    },
    async sumbit(name) {
      let that = this
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          let data = {
            ...this.browerData,
            phone: this.loginByPhone.phone,
            verifyCode: this.loginByPhone.code,
          }
          let res = await apiServices.loginByPhone(data)

          this.account = res.data
          this.selectVal = 5

          if (res.data.length <= 1) {
            //如果只有一个账号直接登录
            console.log('如果只有一个账号直接登录', '-----')
            this.editPassword.account = res.data[0].id
            setTimeout(() => {
              that.login('editPassword')
            }, 2000)
          }
        }
      })
    },
    showVcode(name) {
      this.$refs[name].validateField('phone', async (error) => {
        if (error) {
          this.$Message.error(error)
        } else {
          this.$refs.verify.show()
        }
      })
    },

    //修改密码
    async editSubmit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          if (this.editPassword.password == this.editPassword.newPassword) {
            //校验通过 开始验证验证码
            let res = await apiServices.restPassword({
              id: this.editPassword.account, //必传
              password: gmcryptSm4(
                this.editPassword.password,
                this.forgetbyPhone.phone
              ), //必传
              phone: this.forgetbyPhone.phone,
              verifyCode: this.forgetbyPhone.code,
              mainFlag: this.mainFlag, //是否为主体//必传
            })
            if (res.ret == 0) {
              this.$Message.success({
                background: true,
                content: '修改成功',
                duration: 3,
              })
              this.selectVal = 1
              // this.$router.replace('/rest')
            } else {
              this.$Message.error(res.msg)
            }
          }
        }
      })
    },
    async forgetsumbit(name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          let res = await apiServices.getPhoneAccount({
            phone: this.forgetbyPhone.phone,
            verifyCode: this.forgetbyPhone.code,
          })
          if (res.ret != 0) {
            this.$Message.error(res.msg)
          } else {
            this.account = res.data
            this.selectVal = 4
          }
        }
      })
    },
    selectAccount(val, key) {
      this.mainFlag = val
      this.bindAccountKey = key
    },
    changeSelectVal(val) {
      this.selectVal = val
    },
    // 用户通过了验证
    async onSuccess({ captchaVerification }) {
      this.captchaVerification = captchaVerification
      if (this.selectVal >= 2) {
        let type = this.selectVal == 2 ? 'login' : 'reset'
        let res = await apiServices.sendMsg(
          {
            captchaVerification,
            phone:
              this.selectVal == 2
                ? this.loginByPhone.phone
                : this.forgetbyPhone.phone,
          },
          type
        )
        if (res.ret == 0) {
          this.codeSt = true
          let codeMsg = 60

          this.$Message.success({
            background: true,
            content: '已发送，注意查收',
            duration: 3,
          })
          let auth_timetimer = setInterval(() => {
            codeMsg--
            this.codeMsg = `${codeMsg}秒`
            if (codeMsg <= 0) {
              this.codeSt = false
              this.codeMsg = `获取验证码`
              clearInterval(auth_timetimer)
            }
          }, 1000)
        } else {
          this.$Message.error(res.msg)
        }
      } else {
        if (this.tabval == 1) {
          this.$refs.mainFrom.success(captchaVerification)
        } else {
          this.$refs.subFrom.success(captchaVerification)
        }
      }
    },
    // 用户点击遮罩层，应该关闭模态框
    onClose() {
      this.isShow = false
    },
  },
}
</script>

<style lang="less" scoped src="../assets/css/login.less"></style>
